.headMinWidth {
  min-width: 110px;
}

.azureUserSyncTab > * {
  padding-inline: 1.25rem !important;
}

.azureUsersTableHeight {
  height: 80vh;
}