.btn:hover {
  filter: brightness(125%);
}

.link {
  cursor: pointer;
}

.progress {
  background-color: #dbdada !important;
}

tr.collapse.in {
  display: table-row;
}

.pagination>li>a {
  padding: 7px 7px !important;
}