.resizable-table {
  table-layout:fixed;
  th {
    top: -1px;
  }

  th.sticky-headers{
    position: sticky;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: white;
  }

  th span{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .resize-handle {
    display: inline-block;
    cursor: col-resize;
    width: 14px;
    border-right: 1px solid transparent;
    height: 100%;
  }

  th:hover .handle-icon {
    color: #aaa;
    visibility: visible;
  }

  .handle-icon {
    visibility: hidden;
  }

  .handle-icon.active {
    color: #555 !important;
  }
}

