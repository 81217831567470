@import url('https://fonts.googleapis.com/css?family=Overpass');

.tags-select {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: calc(1.54em + 0.876rem + 2px);
  width: 100%;
  border: 1px solid #8897aa !important;
  border-radius: 0.25rem;
  padding-right: 27px;
  &.invalid {
    border: 1px solid #d9534f !important;
  }

  &:focus-within {
    border: 1px solid #8897aa !important;
  }
  input {
    flex: 1;
    border: none;
    height: calc(1.54em + 0.876rem + 2px);
    font-size: 14px;
    padding: 0.438rem 0.875rem;
    background-color: transparent !important;
    color: #4e5155;
    &:focus {
      outline: transparent;
    }
  }
}

.disabled {
  .tags-select {
    background-color: #f1f1f2;
    opacity: 1;
  }
  svg {
    fill: #f1f1f2;
    opacity: 1;
    cursor: not-allowed;
  }
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: auto 0;
  overflow: hidden;
}

.tag {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 2px 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 4px;
  background: #8897aa !important;
  user-select: none;
  overflow: hidden;
  .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    border-radius: 50%;
    cursor: pointer;
  }
  .tag-button {
    font-size: 1.25rem;
    cursor: pointer;
  }

  .tag-button:hover {
    filter: brightness(80%);
  }
}

@media screen and (max-width: 567px) {
  .tags-select {
    width: calc(100vw - 32px);
  }
}

.add-button {
  padding: 0;
  margin-left: 1rem;
}
