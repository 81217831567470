@-webkit-keyframes RouterLoaderAnimation {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes RouterLoaderAnimation {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes RouterLoaderAnimation {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.Container {
  position: relative;
  z-index: 1020;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: RouterLoaderAnimation .2s;
  animation-fill-mode: forwards;
  margin: -16px -36px -50px -36px;
}

:global(.dark-style) .Container {
  background: rgba(#25282E, .25);
}

.Spinner {
  display: block;
}

.SpinnerEl {
  border-width: 2px !important;
  width: 3rem !important;
  height: 3rem !important;
}

:global(.app-loading) .Container {
  display: none;
}
