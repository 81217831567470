@import './vendor/styles/theme-teldat.scss';
@import './vendor/styles/layouts.scss';
@import './vendor/styles/overrides.scss';

.dropdown {
  .btn-link {
    color: #777 !important;
  }
}

.fs-smaller {
  font-size: 15px !important;
}

.border-py-0 .MuiAutocomplete-inputRoot,
.input-py-0 input[type='text'] {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.modal-shadow-lg .modal-content {
  box-shadow: 20px 13px 20px 20px #181c212d;
  height: 49em;
}
.d-flex-navbar a {
  display: flex;
  align-items: center;
}
.avatar-small {
  width: 32px !important;
  height: 32px !important;
}
.avatar-big {
  width: 140px !important;
  height: 140px !important;
}
.checkbox-cursor {
  label,
  input[type='checkbox'] {
    cursor: pointer;
  }
}
.custom-file-label::after {
  background: $primary-color;
  color: white;
  cursor: pointer;
}
.modal-header .close {
  background-color: $light-gray;
  font-weight: bold;
  right: 0rem;
  top: 0.8em;
  width: 2em;
  padding-bottom: 3px;
}

.bg-light-grey {
  background-color: #f4f4f4;
}
.text-cus-heading {
  font-size: 1.2rem;
}

.object-explorer {
  .list-group-item.active,
  .list-group-item.active:hover,
  .list-group-item.active:focus,
  .nav-pills .nav-link.active,
  .nav-pills .nav-link.active:hover,
  .nav-pills .nav-link.active:focus {
    border-color: #e3e3e3;
    background-color: #e3e3e3;
    color: #394c65;
    font-weight: 500;
  }
  .nav-pills .nav-link {
    border-radius: 0px;
  }
  .custom-header-pad {
    padding-top: 0.57rem;
    padding-bottom: 0.57rem;
  }
}

.modal-content {
  border-radius: 0px;
}

.card,
.card-header {
  border-radius: 0.4rem !important;
}

.card.card-simple {
  box-shadow: none !important;
  border: none !important;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  box-shadow: none;
  outline: none;
}

.table th {
  font-weight: 700 !important;
}
.modal-open .tooltip {
  z-index: 9999;
}
.obj-where-used {
  max-height: 10rem;
  overflow: auto;
}

.category-image {
  width: 4.75rem;
}

.category-progress {
  height: 0.25rem;
}

.object-explorer-button {
  width: 13rem;
}

.action-selector-button {
  .btn {
    width: 6.5rem;
    text-align: left;
  }
}

.url-button {
  width: 3rem;
  padding-left: 0;
  padding-right: 0;
  font-weight: bold;
}

.group-button {
  height: 26px;
}

.modal-selection-list {
  max-height: 430px !important;
}

.reputation-range {
  width: 20rem;
  height: 20px;
  border-radius: 20px;
  vertical-align: middle;
  cursor: pointer;
  margin: 0.5rem;

  &-number {
    width: 4rem;
    text-align: center;
    display: inline-block;
    height: 2rem;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    background: rgb(228, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(228, 0, 0, 1) 0%,
      rgba(254, 225, 43, 1) 38%,
      rgba(114, 218, 103, 1) 100%
    );
    border-radius: 10px;
  }

  &::-moz-range-thumb {
    width: 100%;
    height: 20px;
    cursor: pointer;
    background: rgb(228, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(228, 0, 0, 1) 0%,
      rgba(254, 225, 43, 1) 38%,
      rgba(114, 218, 103, 1) 100%
    );
    border-radius: 10px;
  }

  &::-ms-thumb {
    width: 100%;
    height: 20px;
    cursor: pointer;
    background: rgb(228, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(228, 0, 0, 1) 0%,
      rgba(254, 225, 43, 1) 38%,
      rgba(114, 218, 103, 1) 100%
    );
    border-radius: 10px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    border-radius: 100%;
    background: #ffffff;
    cursor: pointer;
    margin-top: 1px;
  }
}

input[type='range']::-moz-range-thumb {
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background: #ffffff;
  cursor: pointer;
  margin-top: 1px;
}

input[type='range']::-ms-thumb {
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background: #ffffff;
  cursor: pointer;
  margin-top: 1px;
}

.console-text {
  font-family: 'SFMono-Regular', Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
}

.white-space-pre {
  white-space: pre;
}

.configuration-icon {
  margin-right: -55px;
  margin-top: 5px;
  font-size: medium !important;
}

.ct-toast-error {
  white-space: pre;
}

.modal-label {
  color: #8897aa;
  margin-bottom: 1rem;
}

.permit-color {
  background-color: rgb(227, 250, 227);
}

.permit-color:hover {
  background-color: rgb(193, 212, 193);
}

.block-color {
  background-color: rgb(250, 221, 221);
}

.block-color:hover {
  background-color: rgb(212, 188, 188);
}

.detect-color {
  background-color: rgb(255, 255, 255);
}

.detect-color:hover {
  background-color: rgb(217, 217, 217);
}

.dropdown-toggle {
  padding: 0px 5px;
}

.refresh-button {
  height: 1.5rem;
}

.refresh-spinner {
  width: 1.1rem !important;
  height: 1.1rem !important;
  margin-top: 3px;
}

.conf-gutter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 50px;
  padding: 1px 10px;
  text-align: right;
  background: #f7f7f7;
  pre {
    margin: 0;
    white-space: pre-wrap;
    line-height: 25px;
    opacity: 0.5;
    color: #212529;
    display: block;
    font-size: 87.5%;
    font-family: 'SFMono-Regular', Menlo, Monaco, Consolas, 'Liberation Mono',
      'Courier New', monospace;
  }
}

.conf-text {
  pre {
    margin: 0;
    white-space: pre-wrap;
    line-height: 25px;
    display: block;
    font-size: 87.5%;
    color: rgba(24, 28, 33, 0.9);
    overflow: auto;
    font-family: 'SFMono-Regular', Menlo, Monaco, Consolas, 'Liberation Mono',
      'Courier New', monospace;
  }
}

.conf-marker {
  width: 26.89px;
  padding-left: 10px;
  padding-right: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input-prefix {
  display: block;
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;
    left: 16px;
    top: 8.5px;
    z-index: 10;
  }

  &.is-eth {
    &:before {
      content: 'eth';
    }

    .input {
      padding-left: 37px;
    }
  }

  &.is-br {
    &:before {
      content: 'br';
    }

    .input {
      padding-left: 30px;
    }
  }

  &.is-vti {
    &:before {
      content: 'VTI';
    }

    .input {
      padding-left: 36px;
    }
  }
}

.thin-rows {
  td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}

.severity-square {
  height: 15px;
  width: 10px;
  margin-right: 1px;
  border: 1px solid grey;
  background-color: #e5e2e2;
}

.severity-critical {
  background-color: red;
}

.severity-major {
  background-color: #efd19b;
}

.severity-minor {
  background-color: yellow;
}

.severity-audit {
  background-color: #7cd37c;
}

.severity-informational {
  background-color: #7a7af7;
}

.error-page-status {
  font-size: 10rem;
}

.error-page-message {
  font-size: 3.25rem;
}

.visibility-column {
  width: 68px;
}

.ellipsable {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.table-section {
  background-color: #b6c4c5 !important;
  text-align: center;
  height: 35px;
  letter-spacing: 0.08rem;
  text-transform: uppercase;
  font-weight: 600;
}

.changes-table {
  max-width: 300px;
  margin: 0 auto;
}

.tooltip {
  pointer-events: none;
}

// FONT SIZES
.version {
  font-size: 0.75rem;
  color: white;
}

.table-header {
  font-size: 0.8rem !important;
}

// BUTTONS
.btn-primary-transparent {
  background: transparent !important;
  color: $primary-color !important;
}

.btn-primary-transparent-disabled {
  background: transparent !important;
  color: $gray-300 !important;
  cursor: pointer;
}

.btn-danger-transparent {
  background: transparent !important;
  color: #ce4f4b !important;
}

.btn-in-box {
  padding: 0;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
}

// ICONS
.icon-small {
  font-size: 1rem !important;
}

.icon-medium {
  font-size: 1.03rem !important;
}

.icon-table {
  transform: scale(0.7);
}

.icon-table-md {
  transform: scale(0.85);
}

// BREADCRUMB
.icon-breadcrumb {
  font-size: 1.1rem;
  font-weight: 600;
  color: $primary-color !important;
  a {
    color: $primary-color;
  }
  a:hover {
    color: $primary-color;
  }
}

.title-breadcrumb {
  display: inline-block;
  color: #868e96;
}

// LOGS
.log-ips {
  color: #5f7596;
}

.title-log {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.86rem;
}

.content-log {
  margin-top: 2px;
  margin-bottom: 14px;
  font-size: 0.76rem;
  word-wrap: break-word;
}

.col-device-info-img {
  -ms-flex: 0 0 140px;
  flex: 0 0 140px;

  img {
    width: 80px;
  }
}

// SEPARATOR
.border-tertiary {
  border-color: #d0d5dc !important;
}

.required:after {
  color: #e32;
  content: ' *';
  display: inline;
}

.invalid {
  color: #e32;
  font-style: italic;
  font-size: 0.8rem;
}

.active-circle > span > h4 {
  color: $primary-color !important;
}

.active-circle > span {
  border-color: $primary-color !important;
  background-color: $primary-color !important;
  color: white !important;
}

.center-circle {
  margin-left: 50%;
  span {
    margin-left: -15px;
  }
}

.load-file {
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  width: 80%;
  height: 200px;
  padding-right: 100px;
  padding-left: 100px;
  padding-top: 14%;
}

.action-pass {
  color: green;
}

.action-alert {
  color: #eed202;
}

.action-block {
  color: red;
}

.style-success {
  color: green;
}

.style-fail {
  color: red;
}

.style-warning {
  color: #eed202;
}

.device-title {
  margin-left: 10px;
  color: rgb(67, 89, 134);
  text-transform: uppercase;
  font-weight: 600;
}

.device-name {
  margin-left: 10px;
  color: #868e96;
  font-weight: 600;
}

.simple-scroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
}

.progress-wrapper {
  color: #4e5155;

  &-light {
    color: #fff;
  }
}

.progress-bar-label {
  position: absolute;
  width: 100%;
  text-align: center;
  top: -1px;
  font-size: 0.625rem;
}

.pagination-select {
  height: 30px;
}

.align-sub {
  vertical-align: sub;
}

.btn-outline-primary.active.disabled,
.btn-outline-primary.active:disabled {
  background: #435986 !important;
  color: #fff !important;
}

.row-highlight-danger {
  color: #d9534f !important;
  font-weight: bold;
}

.scroll-table {
  height: 300px;
}

.button-fixed-height {
  height: 40px;
}

.align-label {
  padding-top: 3px;
}

.input-number {
  display: inline-block;
  width: 5rem;
  height: 2rem;
  text-align: center;
}

.input-error {
  color: red !important;
  border-color: red !important;
}

.multi-option-row {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e8e8e9;

  .list-group-item {
    border: none;
  }
}

.license-distributor {
  display: flex;
  flex-direction: column;

  &-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-text {
    flex-basis: 100px;
    margin-right: 0.6rem;
  }

  &-progressbar {
    align-self: center;
    flex-grow: 1;

    .info-bar {
      transform: rotate(180deg);
    }
  }
}

.reseller-license-distributor {
  .license-distributor-text {
    flex-basis: 170px;
  }
}

.besafe-calendar {
  .Calendar,
  .Calendar * {
    .Calendar__header {
      padding: 0.5em 2em;
    }

    .Calendar__sectionWrapper {
      min-height: 21em;
    }

    .Calendar__section {
      padding: 0 2em;
    }

    .Calendar__day {
      font-size: 1.2em;
    }

    .calendar-footer {
      padding: 0 2em 2em;
    }
  }
}
.besafe-textarea {
  resize: vertical;
  min-height: 200px;
  overflow: auto;
}
